export const defaultMenuItems = [
    {
        label: 'Импорты данных',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'imports',
        key: 'IMPORTS',
        routerLink: '/data-imports',
    },
    {
        label: 'Цели',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'goals',
        key: 'GOALS',
        submenu: [
            // {
            //     label: 'Каталог',
            //     routerLink: '/goals/list',
            //     iconType: 'svg',
            //     iconName: 'list'
            // },
            {
                label: 'Конфигурации целей',
                routerLink: '/goals/configurations',
                iconType: 'svg',
                iconName: 'list'
            },
            {
                label: 'Создание цели',
                routerLink: '/goals/create',
                iconType: 'svg',
                iconName: 'plus'
            },
        ]
    },
    {
        label: 'Контракты',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'contracts',
        key: 'CONTRACTS',
        submenu: [
            {
                label: 'Список контрактов',
                routerLink: '/contracts/list',
                iconType: 'svg',
                iconName: 'list'
            },
            {
                label: 'Создание контракта',
                routerLink: '/contracts/create',
                iconType: 'svg',
                iconName: 'plus'
            },
            {
                label: 'Заявки на контракты',
                routerLink: '/contracts/requests',
                iconType: 'svg',
                iconName: 'contracts-reguest'
            },
            {
                label: 'Шаблоны контракта',
                routerLink: '/contracts/templates',
                iconType: 'svg',
                iconName: 'settings'
            },
        ]
    },
    {
        label: 'Маршруты и визиты',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'pg',
        iconName: 'routes',
        key: 'AGENT_VISIT_PLAN',
        submenu: [
            {
                label: 'Назначение маршрутов',
                routerLink: '/routes-and-visits/route-assignment',
                iconType: 'svg',
                iconName: 'route_assignment'
            },
            {
                label: 'Массовые активности',
                routerLink: '/routes-and-visits/mass-activities',
                iconType: 'svg',
                iconName: 'mass_activities'
            },
            // {
            //     label: 'Отчетность визитов',
            //     routerLink: '/routes-and-visits/visit-reporting',
            //     iconType: 'svg',
            //     iconName: 'visit_reporting'
            // },
            // {
            //     label: 'Назначение маршрутов NEW',
            //     routerLink: '/routes-and-visits-v2/route-assignment',
            //     iconType: 'svg',
            //     iconName: 'route_assignment'
            // },
        ]
    },
    // {
    //   label: 'Мин. сумма заказа',
    //   toggle: 'close',
    //   mToggle: 'close',
    //   iconType: 'svg',
    //   iconName: 'order-price',
    //   submenu: [
    //     {
    //       label: 'Правила мин. суммы заказа',
    //       routerLink: '/min-order-amount/configurations',
    //       iconType: 'svg',
    //       iconName: 'settings'
    //     },
    //     {
    //       label: 'Создание мин. суммы заказа',
    //       routerLink: '/min-order-amount/create',
    //       iconType: 'svg',
    //       iconName: 'plus'
    //     },
    //   ]
    // },
    {
        label: 'Поставщики',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'vendors',
        key: 'SUPPLIE',
        submenu: [
            {
                label: 'Список поставщиков',
                routerLink: '/vendors',
                iconType: 'svg',
                iconName: 'settings',
                key: 'ADDRESS_SUPPLIE',
            },
            {
                label: 'Создание поставщика',
                routerLink: '/vendors/create',
                iconType: 'svg',
                iconName: 'plus'
            },
        ]
    },
    {
        label: 'Дистрибьюторы',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'distributor',
        key: 'DISTRIBUTOR',
        submenu: [
            {
                label: 'Список дистрибьюторов',
                routerLink: '/distributors/list',
                iconType: 'svg',
                iconName: 'list'
            },
            {
                label: 'Создание дистрибьютора',
                routerLink: '/distributors/create',
                iconType: 'svg',
                iconName: 'plus'
            },
        ]
    },
    {
        label: 'Клиенты',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'client',
        key: 'CLIENT',
        submenu: [
            {
                label: 'Клиенты',
                routerLink: '/clients',
                key: 'CLIENT',
                iconType: 'svg',
                iconName: 'client',
            },
            {
                label: 'Торговые точки',
                key: 'ADDRESS',
                routerLink: '/clients/outlets',
                iconType: 'svg',
                iconName: 'outlet',
            },
            {
                label: 'Территории',
                key: 'TERRITORY',
                routerLink: '/territories',
                iconType: 'svg',
                iconName: 'territories'
            },
            {
                label: 'Документы',
                key: 'DOCUMENTS',
                routerLink: '/documents',
                iconType: 'svg',
                iconName: 'documents'
            },
            {
                label: 'Заявки на открытие',
                routerLink: '/clients/request',
                iconType: 'svg',
                iconName: 'list'
            },
        ]
    },
    {
        label: 'Пользователи',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'users',
        key: 'AGENT_USER',
        submenu: [
            {
                label: 'Пользователи',
                routerLink: '/users/list',
                iconType: 'svg',
                iconName: 'users',
                key: 'AGENT_USER',
            },
            {
                label: 'Должности',
                routerLink: '/users/positions',
                iconType: 'svg',
                iconName: 'position-icon',
                key: 'AGENT_POSITION',
            },
            {
                label: 'Подразделения',
                routerLink: '/users/subdivisions',
                iconType: 'svg',
                iconName: 'subdivision-icon',
                key: 'AGENT_DIVISION',
            }
        ]
    },
    {
        label: 'Задания',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'tasks',
        key: 'TASK',
        submenu: [
            {
                label: 'Активные задания',
                routerLink: '/tasks',
                iconType: 'svg',
                iconName: 'active-tasks',
                key: 'TASK',
            },
            {
                label: 'Конфигурации заданий',
                routerLink: '/tasks/configurations-tasks',
                iconType: 'svg',
                iconName: 'settings'
            },
            {
                label: 'Конфигурации шагов',
                routerLink: '/tasks/configurations-steps',
                iconType: 'svg',
                iconName: 'settings'
            },
            {
                label: 'Создание задания',
                routerLink: '/tasks/create',
                iconType: 'svg',
                iconName: 'plus'
            },
        ]
    },
    {
        label: 'Информация о компании',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'company-info',
        key: 'USER_DISTRIBUTOR',
        submenu: [
            {
                label: 'Личные данные',
                routerLink: '/company-information/company-info',
                iconType: 'svg',
                iconName: 'personal-data'
            },
            {
                label: 'Каталог товаров',
                routerLink: '/company-information/product-catalog',
                iconType: 'svg',
                iconName: 'product-categories'
            },
            {
                label: 'Прайс-листы',
                routerLink: '/company-information/price-lists',
                iconType: 'svg',
                iconName: 'price-lists'
            },
            {
                label: 'Склады',
                routerLink: '/company-information/warehouses',
                iconType: 'svg',
                iconName: 'warehouses'
            },
        ]
    },
    {
        label: 'Заказы',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'orders',
        submenu: [
            {
                label: 'Активные заказы',
                routerLink: '/orders',
                iconType: 'svg',
                iconName: 'list'
            },
            {
                label: 'История заказов',
                routerLink: '/orders/history',
                iconType: 'svg',
                iconName: 'history'
            },
            {
                label: 'Конфигурации аудит-листов',
                routerLink: '/auto-orders/configurations',
                iconType: 'svg',
                iconName: 'settings'
            },
            {
                label: 'Создание аудит-листов',
                routerLink: '/auto-orders/create',
                iconType: 'svg',
                iconName: 'plus'
            },
        ]
    },
    {
        label: 'Оборудование',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'equipment',
        submenu: [
            {
                label: 'Категории',
                routerLink: '/equipment/category',
                iconType: 'svg',
                iconName: 'list'
            },
            {
                label: 'Оборудование',
                routerLink: '/equipment',
                iconType: 'svg',
                iconName: 'equipment'
            },
            {
                label: 'Оборудование на торговых точках',
                routerLink: '/equipment/list-outlets',
                iconType: 'svg',
                iconName: 'sale-equipment'
            },
            {
                label: 'Заявки по работам с оборудованием',
                routerLink: '/equipment/request',
                iconType: 'svg',
                iconName: 'request-equipment'
            },
            {
                label: 'Проверка оборудования',
                routerLink: '/equipment/check',
                iconType: 'svg',
                iconName: 'check-equipment'
            },
        ]
    },
    {
        label: 'ТМЦ материалы',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'tmc-material',
        submenu: [
            {
                label: 'Каталог ТМЦ',
                routerLink: '/tmc-materials/catalog',
                iconType: 'svg',
                iconName: 'list'
            },
            {
                label: 'Перемещения ТМЦ',
                routerLink: '/tmc-materials/transfer',
                iconType: 'svg',
                iconName: 'arrow'
            },
        ]
    },
    {
        label: 'Конфигурации полей',
        routerLink: '/custom-fields',
        iconType: 'svg',
        iconName: 'settings'
    },
    {
        label: 'Отчётность',
        routerLink: '/reports',
        iconType: 'svg',
        iconName: 'reporting-icon'
    },
    // {
    //     label: 'Статистика',
    //     routerLink: '/statistics',
    //     iconType: 'svg',
    //     iconName: 'statistics'
    // },
];

const excludeOwnwerMenuItems = [
    // 'GOALS',
    'DISTRIBUTOR',
];
export const ownerMenuLinks = defaultMenuItems.filter(item => !excludeOwnwerMenuItems.includes(item.key));

export const type1MenuLinks = [
    {
        label: 'Маршруты и визиты',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'pg',
        iconName: 'routes',
        key: 'AGENT_VISIT_PLAN',
        submenu: [
            {
                label: 'Назначение маршрутов',
                routerLink: '/routes-and-visits/route-assignment',
                iconType: 'svg',
                iconName: 'route_assignment'
            },
            {
                label: 'Массовые активности',
                routerLink: '/routes-and-visits/mass-activities',
                iconType: 'svg',
                iconName: 'mass_activities'
            },
            // {
            //     label: 'Отчетность визитов',
            //     routerLink: '/routes-and-visits/visit-reporting',
            //     iconType: 'svg',
            //     iconName: 'visit_reporting'
            // },
        ]
    },
    {
        label: 'Клиенты',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'client',
        key: 'CLIENT',
        submenu: [
            {
                label: 'Клиенты',
                routerLink: '/clients',
                key: 'CLIENT',
                iconType: 'svg',
                iconName: 'client',
            },
            {
                label: 'Торговые точки',
                key: 'ADDRESS',
                routerLink: '/clients/outlets',
                iconType: 'svg',
                iconName: 'outlet',
            },
            {
                label: 'Территории',
                key: 'TERRITORY',
                routerLink: '/territories',
                iconType: 'svg',
                iconName: 'territories'
            },
            {
                label: 'Заявки на открытие',
                routerLink: '/clients/request',
                iconType: 'svg',
                iconName: 'list'
            },
        ]
    },
    {
        label: 'Пользователи',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'users',
        key: 'AGENT_USER',
        submenu: [
            {
                label: 'Пользователи',
                routerLink: '/users/list',
                iconType: 'svg',
                iconName: 'users',
                key: 'AGENT_USER',
            },
            {
                label: 'Должности',
                routerLink: '/users/positions',
                iconType: 'svg',
                iconName: 'position-icon',
                key: 'AGENT_POSITION',
            },
            {
                label: 'Подразделения',
                routerLink: '/users/subdivisions',
                iconType: 'svg',
                iconName: 'subdivision-icon',
                key: 'AGENT_DIVISION',
            }
        ]
    },
    {
        label: 'Заказы',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'orders',
        submenu: [
            // {
            //     label: 'Активные заказы',
            //     routerLink: '/orders',
            //     iconType: 'svg',
            //     iconName: 'list'
            // },
            // {
            //     label: 'История заказов',
            //     routerLink: '/orders/history',
            //     iconType: 'svg',
            //     iconName: 'plus'
            // },
            {
                label: 'Конфигурации аудит-листов',
                routerLink: '/auto-orders/configurations',
                iconType: 'svg',
                iconName: 'settings'
            },
            {
                label: 'Создание аудит-листов',
                routerLink: '/auto-orders/create',
                iconType: 'svg',
                iconName: 'plus'
            },
        ]
    },
    {
        label: 'ТМЦ материалы',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'tmc-material',
        submenu: [
            {
                label: 'Каталог ТМЦ',
                routerLink: '/tmc-materials/catalog',
                iconType: 'svg',
                iconName: 'list'
            },
            {
                label: 'Перемещения ТМЦ',
                routerLink: '/tmc-materials/transfer',
                iconType: 'svg',
                iconName: 'arrow'
            },
        ]
    },
    {
        label: 'Конфигурации полей',
        routerLink: '/custom-fields',
        iconType: 'svg',
        iconName: 'settings'
    },
    {
        label: 'Отчётность',
        routerLink: '/reports',
        iconType: 'svg',
        iconName: 'reporting-icon'
    },
];

export const type2MenuLinks = [
    {
        label: 'Поставщики',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'vendors',
        key: 'SUPPLIE',
        submenu: [
            {
                label: 'Список поставщиков',
                routerLink: '/vendors',
                iconType: 'svg',
                iconName: 'settings',
                key: 'ADDRESS_SUPPLIE',
            },
        ]
    },
    {
        label: 'Клиенты',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'client',
        key: 'CLIENT',
        submenu: [
            {
                label: 'Клиенты',
                routerLink: '/clients',
                key: 'CLIENT',
                iconType: 'svg',
                iconName: 'client',
            },
            {
                label: 'Торговые точки',
                key: 'ADDRESS',
                routerLink: '/clients/outlets',
                iconType: 'svg',
                iconName: 'outlet',
            },
            {
                label: 'Территории',
                key: 'TERRITORY',
                routerLink: '/territories',
                iconType: 'svg',
                iconName: 'territories'
            },
        ]
    },
    {
        label: 'Оборудование',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'equipment',
        submenu: [
            {
                label: 'Категории',
                routerLink: '/equipment/category',
                iconType: 'svg',
                iconName: 'list'
            },
            {
                label: 'Оборудование',
                routerLink: '/equipment',
                iconType: 'svg',
                iconName: 'equipment'
            },
            {
                label: 'Оборудование на торговых точках',
                routerLink: '/equipment/list-outlets',
                iconType: 'svg',
                iconName: 'sale-equipment'
            },
            {
                label: 'Заявки по работам с оборудованием',
                routerLink: '/equipment/request',
                iconType: 'svg',
                iconName: 'request-equipment'
            },
            {
                label: 'Проверка оборудования',
                routerLink: '/equipment/check',
                iconType: 'svg',
                iconName: 'check-equipment'
            },
        ]
    },
    {
        label: 'ТМЦ материалы',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'tmc-material',
        submenu: [
            {
                label: 'Каталог ТМЦ',
                routerLink: '/tmc-materials/catalog',
                iconType: 'svg',
                iconName: 'list'
            },
            {
                label: 'Перемещения ТМЦ',
                routerLink: '/tmc-materials/transfer',
                iconType: 'svg',
                iconName: 'arrow'
            },
        ]
    },
    {
        label: 'Отчётность',
        routerLink: '/reports',
        iconType: 'svg',
        iconName: 'reporting-icon'
    },
];

export const asmMenuLinks = [
    {
        label: 'Маршруты и визиты',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'pg',
        iconName: 'routes',
        key: 'AGENT_VISIT_PLAN',
        submenu: [
            {
                label: 'Назначение маршрутов',
                routerLink: '/routes-and-visits/route-assignment',
                iconType: 'svg',
                iconName: 'route_assignment'
            },
            {
                label: 'Массовые активности',
                routerLink: '/routes-and-visits/mass-activities',
                iconType: 'svg',
                iconName: 'mass_activities'
            },
            {
                label: 'Отчетность визитов',
                routerLink: '/routes-and-visits/visit-reporting',
                iconType: 'svg',
                iconName: 'visit_reporting'
            },
        ]
    },
    {
        label: 'Поставщики',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'vendors',
        key: 'SUPPLIE',
        submenu: [
            {
                label: 'Список поставщиков',
                routerLink: '/vendors',
                iconType: 'svg',
                iconName: 'settings',
                key: 'ADDRESS_SUPPLIE',
            },
            {
                label: 'Создание поставщика',
                routerLink: '/vendors/create',
                iconType: 'svg',
                iconName: 'plus'
            },
        ]
    },
    {
        label: 'Клиенты',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'client',
        key: 'CLIENT',
        submenu: [
            {
                label: 'Клиенты',
                routerLink: '/clients',
                key: 'CLIENT',
                iconType: 'svg',
                iconName: 'client',
            },
            {
                label: 'Торговые точки',
                key: 'ADDRESS',
                routerLink: '/clients/outlets',
                iconType: 'svg',
                iconName: 'outlet',
            },
            {
                label: 'Территории',
                key: 'TERRITORY',
                routerLink: '/territories',
                iconType: 'svg',
                iconName: 'territories'
            },
            {
                label: 'Заявки на открытие',
                routerLink: '/clients/request',
                iconType: 'svg',
                iconName: 'list'
            },
        ]
    },
    {
        label: 'Пользователи',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'users',
        key: 'AGENT_USER',
        submenu: [
            {
                label: 'Пользователи',
                routerLink: '/users/list',
                iconType: 'svg',
                iconName: 'users',
                key: 'AGENT_USER',
            },
            {
                label: 'Должности',
                routerLink: '/users/positions',
                iconType: 'svg',
                iconName: 'position-icon',
                key: 'AGENT_POSITION',
            },
            {
                label: 'Подразделения',
                routerLink: '/users/subdivisions',
                iconType: 'svg',
                iconName: 'subdivision-icon',
                key: 'AGENT_DIVISION',
            }
        ]
    },
    {
        label: 'Задания',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'tasks',
        key: 'TASK',
        submenu: [
            {
                label: 'Активные задания',
                routerLink: '/tasks',
                iconType: 'svg',
                iconName: 'active-tasks',
                key: 'TASK',
            },
            {
                label: 'Конфигурации заданий',
                routerLink: '/tasks/configurations-tasks',
                iconType: 'svg',
                iconName: 'settings'
            },
            {
                label: 'Конфигурации шагов',
                routerLink: '/tasks/configurations-steps',
                iconType: 'svg',
                iconName: 'settings'
            },
            {
                label: 'Создание задания',
                routerLink: '/tasks/create',
                iconType: 'svg',
                iconName: 'plus'
            },
        ]
    },
    {
        label: 'Заказы',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'orders',
        submenu: [
            // {
            //     label: 'Активные заказы',
            //     routerLink: '/orders',
            //     iconType: 'svg',
            //     iconName: 'list'
            // },
            // {
            //     label: 'История заказов',
            //     routerLink: '/orders/history',
            //     iconType: 'svg',
            //     iconName: 'plus'
            // },
            {
                label: 'Конфигурации аудит-листов',
                routerLink: '/auto-orders/configurations',
                iconType: 'svg',
                iconName: 'settings'
            },
            {
                label: 'Создание аудит-листов',
                routerLink: '/auto-orders/create',
                iconType: 'svg',
                iconName: 'plus'
            },
        ]
    },
    {
        label: 'Оборудование',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'equipment',
        submenu: [
            {
                label: 'Категории',
                routerLink: '/equipment/category',
                iconType: 'svg',
                iconName: 'list'
            },
            {
                label: 'Оборудование',
                routerLink: '/equipment',
                iconType: 'svg',
                iconName: 'equipment'
            },
            {
                label: 'Оборудование на торговых точках',
                routerLink: '/equipment/list-outlets',
                iconType: 'svg',
                iconName: 'sale-equipment'
            },
            {
                label: 'Заявки по работам с оборудованием',
                routerLink: '/equipment/request',
                iconType: 'svg',
                iconName: 'request-equipment'
            },
            {
                label: 'Проверка оборудования',
                routerLink: '/equipment/check',
                iconType: 'svg',
                iconName: 'check-equipment'
            },
        ]
    },
    {
        label: 'ТМЦ материалы',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'tmc-material',
        submenu: [
            {
                label: 'Каталог ТМЦ',
                routerLink: '/tmc-materials/catalog',
                iconType: 'svg',
                iconName: 'list'
            },
            {
                label: 'Перемещения ТМЦ',
                routerLink: '/tmc-materials/transfer',
                iconType: 'svg',
                iconName: 'arrow'
            },
        ]
    },
    {
        label: 'Конфигурации полей',
        routerLink: '/custom-fields',
        iconType: 'svg',
        iconName: 'settings'
    },
    {
        label: 'Отчётность',
        routerLink: '/reports',
        iconType: 'svg',
        iconName: 'reporting-icon'
    },
];