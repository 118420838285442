import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'iql-coefficient-item',
  templateUrl: './coefficient-item.component.html',
  styleUrls: ['./coefficient-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoefficientItemComponent {
  @Input() multiplier: number = 0;
  @Input() percentageStep: number = 0;
  @Input() hideRemoveIcon: boolean = false;

  @Output() outputData = new EventEmitter<{ multiplier: number; percentageStep: number }>();
  @Output() delete = new EventEmitter<void>();

  emitChange(): void {
    this.outputData.emit({ percentageStep: this.percentageStep, multiplier: this.multiplier });
  }
}
