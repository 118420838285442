import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from '../../actions/saleplan/product.actions';
import { catchError, map, pluck, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from '../../reducers';
import { ProductService } from '@Mesh/core/services/api/saleplan/product.service';

@Injectable()
export class ProductEffects {
  constructor(private _actions$: Actions, private productService: ProductService, private readonly _store: Store<State>) {
  }

  productsWithOrdersLoading$ = createEffect(
    () => this._actions$.pipe(
      ofType(fromActions.loadProductsWithOrders),
      switchMap(params => this.productService.getProduct(params).pipe(pluck('content'))),
      map(products => fromActions.productsWithOrdersLoaded({ products }))
    )
  );

  productsLoading$ = createEffect(
    () => this._actions$.pipe(
      ofType(fromActions.loadProducts),
      switchMap(params => {
        const data = { ...params };
        delete data['type'];
        return this.productService.getProduct(data).pipe(
          map(products => fromActions.productsLoaded({ products })));
      })
    )
  );

  getProducts$ = createEffect(
    () => this._actions$.pipe(
      ofType(fromActions.loadProductsForGoal),
      switchMap(({ planIds, outletId, page }) => {
        return this.productService.getGoalProducts(planIds, outletId, page).pipe(
          map(products => fromActions.productsLoaded({ products })));
      })
    )
  );

  getShoppingCartProducts$ = createEffect(
    () => this._actions$.pipe(
      ofType(fromActions.loadProductsForShoppingCart),
      switchMap(({ outletId, materialIds }) => {
        return this.productService.getShoppingCartProducts(outletId, materialIds).pipe(
          map(products => fromActions.productsForShoppingCartLoaded({ products })));
      })
    )
  );

  loadAllProduct$ = createEffect(
    () => this._actions$.pipe(
      ofType(fromActions.loadAllProduct),
      switchMap(({ criteria }) => this.productService.getAllProducts(criteria)
        .pipe(
          map(data => fromActions.loadAllProductSuccess({ data })),
          catchError(err => ([fromActions.loadAllProductFailure(err)]))
        )
      )
    ));

  loadProductsByProductIds$ = createEffect(
      () => this._actions$.pipe(
        ofType(fromActions.loadProductsByProductIds),
        switchMap(({ productIds, distrIds }) => this.productService.getAllProducts({ productIds, pageNumber: 0, pageSize: productIds.length, distrIds: [distrIds || 1] })
          .pipe(
            map(data => fromActions.loadProductsByProductIdsSuccess({ data })),
            catchError(err => ([fromActions.loadProductsByProductIdsFailure(err)]))
          )
        )
      ));

  loadTopLevelCategories$ = createEffect(
    () => this._actions$.pipe(
      ofType(fromActions.loadTopLevelCategories),
      switchMap(() => this.productService.getTopLevelCategories()
        .pipe(
          map(data => fromActions.loadTopLevelCategoriesSuccess({ data })),
          catchError(err => ([fromActions.loadTopLevelCategoriesFailure(err)]))
        )
      )
    ));

  loadAllCategories$ = createEffect(
    () => this._actions$.pipe(
      ofType(fromActions.loadAllCategories),
      switchMap(({ criteria }) => this.productService.getAllCategories(criteria)
        .pipe(
          map(data => fromActions.loadAllCategoriesSuccess({ data })),
          catchError(err => ([fromActions.loadAllCategoriesFailure(err)]))
        )
      )
    ));

  loadManufacturer$ = createEffect(
    () => this._actions$.pipe(
      ofType(fromActions.loadManufacturer),
      switchMap(({ criteria }) => this.productService.getManufacturer(criteria)
        .pipe(
          map(data => fromActions.loadManufacturerSuccess({ data: data.content })),
          catchError(err => ([fromActions.loadManufacturerFailure(err)]))
        )
      )
    ));

  loadBrands$ = createEffect(
    () => this._actions$.pipe(
      ofType(fromActions.loadBrands),
      switchMap(({ criteria }) => this.productService.getBrands(criteria)
        .pipe(
          map(data => fromActions.loadBrandsSuccess({ data: data.content })),
          catchError(err => ([fromActions.loadBrandsFailure(err)]))
        )
      )
    ));
}
