import {
  goToNotification,
  loadNotifications,
  readNotification,
} from '@Mesh/store/actions/notification/notification.actions';
import * as moment from 'moment';
import { IUserData } from '@Mesh/core/models/user';
import { IUserInfo } from './../../../../../core/models/user';
import { AuthService } from '@Mesh/core/services/api/auth/auth.service';
import {
  Notification,
  NotificationTokenRequest,
  NotificationRequest,
} from '@Mesh/core/models/notification';
import { mergeMap, filter, take } from 'rxjs/operators';
import { getUser } from '@Mesh/store/selectors/auth/auth.selector';
import { updatePushToken } from '@Mesh/store/actions/notification/notification.actions';
import { State } from '@Mesh/store/reducers';
import { Store, select } from '@ngrx/store';
import { FIREBASE } from '@Env/environment';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  HostListener,
  ElementRef,
} from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import {
  getNotifications,
  getReadNotificationStatus,
} from '@Mesh/store/selectors/notification/notification.selector';
import { PostingStatus } from '@Mesh/core/models/external/loadable';
import { WebsocketService } from '@Mesh/core/services/api/websocket/websocket.service';

@Component({
  selector: 'iql-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent implements OnInit {
  user$ = this.store.pipe(select(getUser));
  notifications$ = this.store.pipe(select(getNotifications));
  readNotificationStatus$ = this.store.pipe(select(getReadNotificationStatus));
  opened = false;

  constructor(
    private authService: AuthService,
    private angularFireMessaging: AngularFireMessaging,
    private websocketService: WebsocketService,
    readonly store: Store<State>,
    private eref: ElementRef
  ) {}

  openNotification(notification: Notification): void {
    this.store.dispatch(readNotification({ id: notification.id }));
    this.store.dispatch(goToNotification({ notification }));
  }

  read(id: number): void {
    return this.store.dispatch(readNotification({ id }));
  }

  dateAtFromNow(date: any): string {
    if (date) {
      return moment.utc(date).from(moment.now());
    }
  }

  trunc(str: string, length: number): string {
    return str.substr(0, length - 1) + (str.length > length ? '...' : '');
  }

  getNotificationIcon(entityType): string[] {
    return {
      report: '/assets/img/notifications/document.png',
      support: '/assets/img/notifications/chat.png',
      'users-chat': '/assets/img/notifications/chat.png',
    }[entityType];
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent): void {
    if (!this.eref.nativeElement.contains(event.target)) {
      this.opened = false;
    }
  }

  toggle(): void {
    this.opened = !this.opened;
  }

  loadNotification(): void {
    const params: NotificationRequest = {
      userId: (this.authService.user as IUserData).id,
      size: 10,
      page: 0,
      sort: 'id',
      direction: 'desc'
    };
    this.store.dispatch(loadNotifications({ params }));
  }

  ngOnInit(): void {
    this.angularFireMessaging.messages.subscribe((payload) => {
      console.error('new message received. ', payload);
    });
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        const params = {
          userId: (this.authService.user as IUserData).id,
          deviceId: 'web',
          token
        } as NotificationTokenRequest;
        this.store.dispatch(updatePushToken({ params }));
      },
      (err) => {
        console.log('Unable to get permission to notify.', err);
      }
    );
    this.angularFireMessaging.messaging.subscribe((_messaging) => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    });

    this.websocketService.connect();
    this.websocketService.messagesSubject$
      .pipe(filter(({ entityType }) => entityType === 'NOTIFICATION'))
      .subscribe(() => {
        setTimeout(() => {
          this.loadNotification();
        }, 500);
      });

    this.loadNotification();
    this.readNotificationStatus$
      .pipe(filter((status) => status === PostingStatus.Posted))
      .subscribe(() => {
        this.loadNotification();
      });
  }
}
