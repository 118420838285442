import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { USER_DISTRIBUTOR_URL } from '@Env/environment';
import { objectToParams } from '@Mesh/shared/helpers/object.helpers';
import {
  Address,
  AddressCreatePayload,
  AddressRemoveParams,
  AddressSearchByClientParams,
  AddressSearchParams,
  AddressShort,
  AddressShortRequestParams,
  AddressStatusUpdateRequest,
} from '@Mesh/core/models/address';
import { IPageableList } from '@Mesh/core/models/pageable';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  constructor(private readonly http: HttpClient) {}

  searchAddress(searchAddressParams: Partial<AddressSearchParams>): Observable<IPageableList<Address>> {
    const params = objectToParams(searchAddressParams);
    return this.http.get<IPageableList<Address>>(`${USER_DISTRIBUTOR_URL}/v1/adm/address`, { params });
  }

  searchAddressShort(request: Partial<AddressShortRequestParams>): Observable<IPageableList<AddressShort>> {
    return this.http.put<IPageableList<AddressShort>>(`${USER_DISTRIBUTOR_URL}/v1/address/search`, request);
  }

  searchAddressByClient(searchAddressParams: Partial<AddressSearchByClientParams>): Observable<IPageableList<Address>> {
    const params = objectToParams(searchAddressParams);
    return this.http.get<IPageableList<Address>>(`${USER_DISTRIBUTOR_URL}/v1/address`, { params });
  }

  createAddress(payload: AddressCreatePayload): Observable<Address> {
    return this.http.post<Address>(`${USER_DISTRIBUTOR_URL}/v1/adm/address`, payload);
  }

  updateAddress(payload: AddressCreatePayload): Observable<Address> {
    return this.http.put<Address>(`${USER_DISTRIBUTOR_URL}/v1/adm/address`, payload);
  }

  removeAddress(payload: AddressRemoveParams): Observable<void> {
    const params = objectToParams(payload);
    return this.http.delete<void>(`${USER_DISTRIBUTOR_URL}/v1/adm/address`, { params });
  }

  updateAddressStatus(addressId: number, isClosed: boolean): Observable<Address> {
    const body: AddressStatusUpdateRequest = { addressId, isClosed };
    return this.http.put<Address>(`${USER_DISTRIBUTOR_URL}/v1/adm/address/status`, body);
  }
}
