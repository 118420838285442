import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from '../../actions/saleplan/bonus.actions';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { State } from '../../reducers';
import { GetErrorType } from '@Mesh/core/models/APPmodels/errors';
import { BonusService } from '@Mesh/core/services/api/saleplan/bonus.service';
import { selectSalePlans } from '@Mesh/store/selectors/saleplan/saleplan.selectors';
import moment from 'moment';
import { selectUserDistributorId } from '../../selectors/auth/auth.selector';

@Injectable()
export class BonusEffects {
  constructor(private _actions$: Actions, private bonusService: BonusService, private readonly _store: Store<State>) {}

  loadBonus = createEffect(() =>
    this._actions$.pipe(
      ofType(fromActions.loadBonus),
      switchMap((props) =>
        this.bonusService.getBonus(props).pipe(
          map((bonus) => fromActions.bonusLoaded({ bonus })),
          catchError((err) => [fromActions.bonusLoadError({ error: GetErrorType(err) })])
        )
      )
    )
  );

  toggleBonusConfiguraitionRule = createEffect(() =>
    this._actions$.pipe(
      ofType(fromActions.salePlanBonusAction.toggleBonusConfigurationRule),
      switchMap(({ request }) =>
        this.bonusService.toggleBonusConfigurationRule(request).pipe(
          switchMap((result) => [
            fromActions.salePlanBonusAction.toggleBonusConfigurationRuleSuccess(),
            fromActions.salePlanBonusAction.loadBonusConfiguration({
              addressId: request.map((request) => request.addressId),
            }),
          ]),
          catchError((err) => [
            fromActions.salePlanBonusAction.toggleBonusConfigurationRuleError({ error: GetErrorType(err) }),
          ])
        )
      )
    )
  );
  updateBonus = createEffect(() =>
    this._actions$.pipe(
      ofType(fromActions.updateBonus),
      withLatestFrom(this._store.pipe(select(selectSalePlans))),
      switchMap(([{ addressId, date }, plans]) =>
        this.bonusService.getBonus({ addressId, date, planIds: plans.map((p) => p.planId) }).pipe(
          switchMap((bonus) => {
            if (moment(date).startOf('month').isSame(moment().startOf('month'))) {
              return [
                fromActions.bonusLoaded({ bonus }),
                fromActions.loadPredictBonus({ addressId, planIds: plans.map((p) => p.planId) }),
              ];
            }
            return [fromActions.bonusLoaded({ bonus }), fromActions.clearPredictBonus()];
          }),
          catchError((err) => [fromActions.bonusLoadError({ error: GetErrorType(err) })])
        )
      )
    )
  );
  loadPredictBonus = createEffect(() =>
    this._actions$.pipe(
      ofType(fromActions.loadPredictBonus),
      switchMap((props) =>
        this.bonusService.getPredictBonus(props).pipe(
          map((bonus) => fromActions.predictBonusLoaded({ bonus })),
          catchError((err) => [fromActions.predictBonusLoadError({ error: GetErrorType(err) })])
        )
      )
    )
  );
  loadPredictBonusClient = createEffect(() =>
    this._actions$.pipe(
      ofType(fromActions.salePlanBonusAction.loadPredictBonusClient),
      switchMap((props) =>
        this.bonusService.getPredictBonusClient(props.clientId).pipe(
          map((data) => fromActions.salePlanBonusAction.loadPredictBonusClientSuccess({ data })),
          catchError((err) => [
            fromActions.salePlanBonusAction.loadPredictBonusClientError({ error: GetErrorType(err) }),
          ])
        )
      )
    )
  );
  loadPredictBonusOutlets = createEffect(() =>
    this._actions$.pipe(
      ofType(fromActions.salePlanBonusAction.loadPredictBonusOutlets),
      switchMap((props) =>
        this.bonusService.getPredictBonusOutlets(props.addressId).pipe(
          map((data) =>
            fromActions.salePlanBonusAction.loadPredictBonusOutletsSuccess({
              data: { addressId: props.addressId, bonus: data },
            })
          ),
          catchError((err) => [
            fromActions.salePlanBonusAction.loadPredictBonusOutletsError({ error: GetErrorType(err) }),
          ])
        )
      )
    )
  );
  loadBonusOutlets = createEffect(() =>
    this._actions$.pipe(
      ofType(fromActions.salePlanBonusAction.loadBonusOutlets),
      switchMap((props) =>
        this.bonusService.getBonusOutlets(props.addressId).pipe(
          map((data) =>
            fromActions.salePlanBonusAction.loadBonusOutletsSuccess({
              data: { addressId: props.addressId, bonus: data },
            })
          ),
          catchError((err) => [fromActions.salePlanBonusAction.loadBonusOutletsError({ error: GetErrorType(err) })])
        )
      )
    )
  );

  loadBonusConfiguration = createEffect(() =>
    this._actions$.pipe(
      ofType(fromActions.salePlanBonusAction.loadBonusConfiguration),
      withLatestFrom(this._store.select(selectUserDistributorId)),
      switchMap(([props, distributorId]) =>
        this.bonusService.getBonusConfiguration({ ...props, distrIds: [distributorId] }).pipe(
          map((configurations) => fromActions.salePlanBonusAction.loadBonusConfigurationSuccess({ configurations })),
          catchError((err) => [fromActions.salePlanBonusAction.loadBonusOutletsError({ error: GetErrorType(err) })])
        )
      )
    )
  );

  loadBonusConfigCategory = createEffect(() =>
    this._actions$.pipe(
      ofType(fromActions.salePlanBonusAction.loadBonusConfigCategory),
      switchMap(() =>
        this.bonusService.getBonusConfigCategory().pipe(
          map((data) => fromActions.salePlanBonusAction.loadBonusConfigCategorySuccess({ categories: data.content })),
          catchError((err) => [
            fromActions.salePlanBonusAction.loadBonusConfigCategoryError({ error: GetErrorType(err) }),
          ])
        )
      )
    )
  );

  loadBonusConfigurationById = createEffect(() =>
    this._actions$.pipe(
      ofType(fromActions.salePlanBonusAction.loadBonusConfigurationById),
      switchMap((props) =>
        this.bonusService.getBonusConfigurationById(props.id).pipe(
          map((currentConfiguration) =>
            fromActions.salePlanBonusAction.loadBonusConfigurationByIdSuccess({ currentConfiguration })
          ),
          catchError((err) => [fromActions.salePlanBonusAction.loadBonusOutletsError({ error: GetErrorType(err) })])
        )
      )
    )
  );
}
