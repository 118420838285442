import {
  BonusConfigCategory,
  BonusConfiguration,
  BonusConfigurationRule,
  BonusConfigurationSearchParams,
} from '../../../models/bonus/bonus-configuration';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SALEPLAN_DISTRIBUTOR_URL } from '@Env/environment';
import { Observable, of } from 'rxjs';
import { Bonus } from '@Mesh/core/models/APImodels/saleplan/bonus';
import { BonusClient } from '@Mesh/core/models/bonus/bonus-client';
import { BonusOutlets } from '@Mesh/core/models/bonus/bonus-outlets';
import { BonusConfigurationRuleRequest } from '@Mesh/core/models/bonus/bonus-configuration-rule-request';
import { GoalIdType, INewGoal } from '../../../../pages/goals/components/create-goals/createGoalTypes';
import { IPageableList } from '../../../models/pageable';

@Injectable({
  providedIn: 'root',
})
export class BonusService {
  readonly connectionString = `${SALEPLAN_DISTRIBUTOR_URL}/v1/bonus`;

  constructor(private readonly http: HttpClient) {}

  getBonus({ addressId, planIds, date }: { addressId: number; planIds: number[]; date?: string }): Observable<Bonus[]> {
    if (!addressId) {
      return of([]);
    }

    let params = new HttpParams();
    params = params.append('addressId', `${addressId}`);
    params = params.append('showOffPlan', 'true');
    if (date) {
      params = params.append('bonusDate', `${date}`);
    }
    if (planIds) {
      planIds.forEach((p) => (params = params.append('planIds', `${p}`)));
    }
    return this.http.get<Bonus[]>(`${this.connectionString}`, { params });
  }

  getBonusConfiguration({
    addressId = [],
    planId = [],
    strategy,
    distrIds,
  }: BonusConfigurationSearchParams): Observable<BonusConfiguration[]> {
    const params = new HttpParams({
      fromObject: {
        ...(addressId.length && { addressId: addressId.map(String) }),
        ...(planId.length && { planId: planId.map(String) }),
        ...(strategy && { strategy }),
        distrIds: distrIds.map(String),
      },
    });

    return this.http.get<BonusConfiguration[]>(`${this.connectionString}/configuration`, { params });
  }

  getBonusConfigurationById(bonusConfigId: number): Observable<BonusConfiguration> {
    return this.http.get<BonusConfiguration>(`${this.connectionString}/configuration/${bonusConfigId}`);
  }

  removeBonusConfiguration(strategy: string, bonusConfigId: number): Observable<void> {
    return this.http.delete<void>(`${this.connectionString}/configuration/${strategy}/${bonusConfigId}`);
  }

  getBonusConfigCategory(): Observable<IPageableList<BonusConfigCategory>> {
    return this.http.get<IPageableList<BonusConfigCategory>>(`${this.connectionString}/configuration/category`);
  }

  getPredictBonus({
    addressId,
    planIds,
  }: {
    addressId: number;
    planIds: number[];
    date?: string;
  }): Observable<Bonus[]> {
    if (!addressId) {
      return of([]);
    }

    let params = new HttpParams();
    params = params.append('showOffPlan', 'true');
    params = params.append('addressId', `${addressId}`);

    if (planIds) {
      planIds.forEach((p) => (params = params.append('planIds', `${p}`)));
    }
    return this.http.get<Bonus[]>(`${this.connectionString}/predict`, { params });
  }

  getPredictBonusClient(clientId: number): Observable<BonusClient> {
    const params = new HttpParams().append('clientId', `${clientId}`);
    return this.http.get<BonusClient>(`${this.connectionString}/client/predict`, { params });
  }

  getBonusOutlets(addressId: number): Observable<BonusOutlets[]> {
    const params = new HttpParams().append('addressId', `${addressId}`);
    return this.http.get<BonusOutlets[]>(`${this.connectionString}`, { params });
  }

  getPredictBonusOutlets(addressId: number): Observable<BonusOutlets[]> {
    const params = new HttpParams().append('addressId', `${addressId}`);
    return this.http.get<BonusOutlets[]>(`${this.connectionString}/predict`, { params });
  }

  toggleBonusConfigurationRule(request: BonusConfigurationRuleRequest[]): Observable<any> {
    return this.http.post<any>(`${this.connectionString}/configuration/rules`, request);
  }

  getBonusConfigurationRule(planId: number): Observable<BonusConfigurationRule[]> {
    return this.http.get<BonusConfigurationRule[]>(`${this.connectionString}/${planId}/rules`);
  }

  createBonusConfigurationByType(strategy: GoalIdType, data: INewGoal): Observable<BonusConfiguration> {
    return this.http.post<BonusConfiguration>(`${this.connectionString}/configuration/${strategy}`, data);
  }

  activateBonusPlansForUsers(planIds: number[]): Observable<any> {
    return this.http.post<any>(`${this.connectionString}/user/activate`, { planIds });
  }
}
