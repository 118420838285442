import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CoefficientValueType, CoefficientType } from '@Mesh/pages/goals/components/create-goals/createGoalTypes';

@Component({
  selector: 'iql-percent-coefficient',
  templateUrl: './percent-coefficient.component.html',
  styleUrls: ['./percent-coefficient.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PercentCoefficientComponent),
      multi: true,
    },
  ],
})
export class PercentCoefficientComponent implements ControlValueAccessor {
  @Input() labelText: string;
  @Input() labelWidth: string;
  @Input() isItem: boolean;
  @Input() placeholder: string = '0';
  @Input() coefficientValueType: CoefficientValueType;
  @Input() isRequired: boolean;

  CoefficientType = CoefficientType;
  CoefficientValueType = CoefficientValueType;

  private _data: { type: CoefficientType; value: number | null }[] = [];

  @Output() selectData: EventEmitter<{ type: CoefficientType; value: number | null }[]> = new EventEmitter();

  onChange: (value: { type: CoefficientType; value: number | null }[]) => void = () => {};
  onTouched: () => void = () => {};

  private getDefaultData(): { type: CoefficientType; value: number | null }[] {
    return [
      { type: CoefficientType.GOLD, value: null },
      { type: CoefficientType.SILVER, value: null },
      { type: CoefficientType.IRON, value: null },
    ];
  }

  get data(): { type: CoefficientType; value: number | null }[] {
    return this._data;
  }

  @Input()
  set data(value: { type: CoefficientType; value: number | null }[]) {
    this._data = value && value.length ? value : this.getDefaultData();
    this.onChange(this._data);
  }

  writeValue(value: { type: CoefficientType; value: number | null }[]): void {
    this._data = value && value.length ? value : this.getDefaultData();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  changeValue(value: number | null, index: number): void {
    this._data[index].value = value;
    this.onChange(this._data);
    this.selectData.emit(this._data);
  }
}
